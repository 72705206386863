/* *******************************************
 // LICENSE INFORMATION
 // The code, "Detecting Smartphones Using JavaScript"
 // by Anthony Hand, is licensed under a Creative Commons
 // Attribution 3.0 United States License.
 //
 // Anthony Hand, ahand@hand-interactive.com
 // Web: www.hand-interactive.com
 //
 // License info: http://creativecommons.org/licenses/by/3.0/us/
 //
 // This code is provided “AS IS” with no expressed or implied warranty.
 // You have the right to use this code or any portion of it
 // so long as you provide credit toward myself as the original author.
 //
 //
 // WARNING: JavaScript support is poor among 'feature phones'.
 //   These features may only work for (most) smartphones,
 //   excluding those with known poor support for JavaScript,
 //   such as older BlackBerry, PalmOS, and Windows Mobile devices.
 //
 // *******************************************
 */

//Initialize some initial string variables we'll look for later.
var deviceAndroid = "android";
var deviceIphone = "iphone";
var deviceIpod = "ipod";
var deviceIpad = "ipad";

var deviceWebOS = "webos"; //For Palm's new WebOS devices

//Initialize our user agent string.
var uagent = navigator.userAgent.toLowerCase();


//**************************
// Detects if the current device is an iPhone.
window.DetectIphone = function() {
    return uagent.search(deviceIphone) > -1 && !DetectIpod();
};

//**************************
// Detects if the current device is an iPod Touch.
window.DetectIpod = function() {
    return uagent.search(deviceIpod) > -1;
};

//**************************
// Detects if the current device is an iPad.
window.DetectIpad = function() {
  return uagent.search(deviceIpad) > -1
  // iPad on iOS 13 detection
  || (uagent.includes("mac") && "ontouchend" in document);
};

//**************************
// Detects if the current device is an Android OS-based device.
window.DetectAndroid = function() {
    return uagent.search(deviceAndroid) > -1;
};

//**************************
// Detects if the current browser is on a Palm device
//   running the new WebOS.
window.DetectPalmWebOS = function() {
    return uagent.search(deviceWebOS) > -1;
};
