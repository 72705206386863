import 'axios'
import _ from 'underscore'
import { DateTime, Interval } from 'luxon'
import { datadogRum } from '@datadog/browser-rum'
import smoothscroll from 'smoothscroll-polyfill'

window._ = _
window.LuxonDateTime = DateTime
window.LuxonInterval = Interval

window.dateFormat = require('dateformat') // TODO: should be deprecated after implementing luxon
window.objectFitImages = require('object-fit-images')
window.generateUUID = require('uuid/v4')
window.ttiPolyfill = require('tti-polyfill')

smoothscroll.polyfill()

// Ref: https://webpack.js.org/guides/dependency-management/#requirecontext
const cache = {}

function importAll(r) {
  r.keys().forEach((key) => {
    cache[key] = r(key)
  })
}

if (appConfig.datadog && appConfig.datadog.rumEnabled && IS_PRODUCTION) {
  datadogRum.init({
    applicationId: appConfig.datadog.applicationId,
    clientToken: appConfig.datadog.clientToken,
    env: appConfig.datadog.environment,
    service: appConfig.datadog.service,
    trackInteractions: appConfig.datadog.trackInteractions,
    sampleRate: appConfig.datadog.sampleRate,
    site: 'datadoghq.com'
  })
}

importAll(require.context('./', true, /\.js$/))
